









/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import DotsSvg from '@svg/Dots.vue';


/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        DotsSvg
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class LoginButton extends Vue {};

