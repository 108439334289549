















/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import PageMain from '@part/layout/PageMain.vue';
import LoginButton from '@part/elements/LoginButton.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import lang from '@lang/home.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        LoginButton,
        ArrowForwardSvg
    },
    props: [],
    data() {
        return {
            
            pageData: {},
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        }
    },
    mounted() {
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Home extends Vue {}
